<template>
  <div class="page-404">
    <p>경로를 찾을 수 없습니다.</p>
    <p>
      <router-link to="/list">문의리스트로 이동하기</router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: "404",
};
</script>

<style lang="scss">
@import "~@/color";

.page-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-size: 16px;
  color: $gray06;

  p:nth-child(2) {
    font-size: 14px;
    a {
      color: $violet01;
      text-decoration: underline;
    }
  }

  p + p {
    margin-top: 10px;
  }
}
</style>
